import tableMutations from "../../templates/table/mutations";
import Vue from "vue";
import {
  CLEAR_FILTER,
  SET_ITEMS
} from "@/store/templates/table/mutation-types";
import { isNil } from "lodash";
import { setEntities } from "@/store/templates/entities";

export default {
  ...tableMutations,
  [SET_ITEMS](state, { ids, entities, meta }) {
    if (!isNil(meta)) {
      Object.assign(state.pagination, {
        totalItems: meta.total,
        page: meta.current_page,
        rowsPerPage: parseInt(meta.per_page)
      });
    } else {
      Object.assign(state.pagination, {
        totalItems: ids.length,
        page: 1,
        rowsPerPage: 20
      });
    }
    if (!isNil(entities.entity)) {
      setEntities(state, entities, true);
    }
    ids.unshift(ids[0]);
    state.ids = ids;
  },
  [CLEAR_FILTER](state) {
    Vue.set(state, "filters", state.baseFilters || {});
  }
};
