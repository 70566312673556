<template>
  <table-overview
    title="Websites monthly metrics"
    icon="far fa-fw fa-globe"
    store="metrics-website-monthly"
    :headers="headers"
    :force-initial-sort="lastMonthString"
    descending
    sticky-thead
    sticky-first-column
    striped-columns
    searchable
    class="websites-stats-timetable"
  >
    <template slot="toolbar-additional-search">
      <v-select
        v-model="sort"
        :items="sortables"
        label="Sort by"
        solo-inverted
        flat
        class="pl-3"
        style="z-index: 13; max-width: 300px;"
        hide-details
      />
      <v-select
        slot="toolbar-additional-search"
        v-model="dataTypesArray"
        :items="dataTypesSelectable"
        label="Data types"
        solo-inverted
        multiple
        flat
        class="pl-3"
        style="z-index: 13; max-width: 300px;"
        hide-details
      />
    </template>
    <metrics-website-monthly-table-row
      slot="table-row"
      slot-scope="{ item, index }"
      :website-id="item"
      :index="index"
    />
    <metrics-website-monthly-filters
      slot="filters"
      store="metrics-website-monthly"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../../components/generic/overview/TableOverview";
import Moment from "moment";
import websitesStatsTimetableModule from "@/store/modules/metrics-website-monthly";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers as createNamespacedHelpersFromVuex } from "vuex";
import { createNamespacedHelpers } from "@/store/helpers";
import { FETCH } from "@/store/templates/table/action-types";
import MetricsWebsiteMonthlyTableRow from "@/components/statistics/websites/MetricsWebsiteMonthlyTableRow";
import MetricsWebsiteMonthlyFilters from "@/components/statistics/websites/MetricsWebsiteMonthlyTableFilter";

const { mapGetters } = createNamespacedHelpersFromVuex(
  "metrics-website-monthly"
);
const { mapComputedFilters } = createNamespacedHelpers(
  "metrics-website-monthly"
);

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "metrics-website-monthly",
  websitesStatsTimetableModule
);

export default {
  name: "websites-stats-timetable-view",
  components: {
    MetricsWebsiteMonthlyFilters,
    MetricsWebsiteMonthlyTableRow,
    TableOverview
  },
  data: () => ({
    dataTypesSelectable: [
      {
        text: "Conversions",
        value: "total_conversions"
      },
      {
        text: "Visitors",
        value: "unique_users"
      }
    ],
    sortables: [
      {
        text: "Conversions",
        value: "total_conversions"
      },
      {
        text: "Conversions monthly change",
        value: "total_conversions_monthly_change"
      },
      {
        text: "Conversions yearly change",
        value: "total_conversions_yearly_change"
      },
      {
        text: "Visitors",
        value: "unique_users"
      },
      {
        text: "Visitors monthly change",
        value: "unique_users_monthly_change"
      },
      {
        text: "Visitors yearly change",
        value: "unique_users_yearly_change"
      }
    ]
  }),
  computed: {
    ...mapComputedFilters(["from", "to", "by", "dataTypes", "sort"]),
    ...mapGetters(["dataCountsHeaders"]),
    headers() {
      return [
        { text: "Website", value: "domain", sortable: false },
        ...this.dateHeaders
      ];
    },
    dateHeaders() {
      return this.dataCountsHeaders.map(date => {
        let dateFormat = "MMM YYYY";
        if (date.length === 4) {
          dateFormat = "YYYY";
          date += "-01";
        }
        return {
          text: Moment(date).format(dateFormat),
          value: date,
          sortable: true,
          align: "right"
        };
      });
    },
    lastMonthString() {
      return Moment()
        .subtract(1, "months")
        .format("YYYY-MM");
    },
    dataTypesArray: {
      get() {
        return this.dataTypes.split(",");
      },
      set(val) {
        if (val.length > 0) {
          this.dataTypes = val.join(",");
        }
      }
    }
  },
  watch: {
    async dataTypes() {
      await this.$store.dispatch(`metrics-website-monthly/${FETCH}`);
    },
    async sort() {
      await this.$store.dispatch(`metrics-website-monthly/${FETCH}`);
    }
  },
  ...overviewLifecycleMethods
};
</script>
<style lang="scss">
.websites-stats-timetable {
  th {
    color: white !important;
    background-color: #2e6fb4 !important;
  }
  td:nth-child(1n + 2) {
    text-align: right;
  }
}
</style>
