import { render, staticRenderFns } from "./VAutocompleteSubject.vue?vue&type=template&id=b0c3344a&"
import script from "./VAutocompleteSubject.vue?vue&type=script&lang=js&"
export * from "./VAutocompleteSubject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VChip,VIcon,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle})
