<template>
  <v-form>
    <between-date-input v-model="betweenDates" type="month" />
    <v-select-locale v-model="locales" multiple />
    <v-autocomplete-website v-model="websites" multiple />
    <v-autocomplete-subject v-model="subjects" multiple />
    <v-flex>
      <v-subheader class="pl-0">Minimum visitors:</v-subheader>
      <v-layout row>
        <v-flex shrink style="width: 5em">
          <v-text-field
            v-model="minimumVisitors"
            class="mt-0 number-input__hide-arrows"
            hide-details
            single-line
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex class="px-2">
          <v-slider
            v-model="minimumVisitors"
            thumb-label="always"
            max="30000"
          ></v-slider>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex>
      <v-subheader class="pl-0">Minimum Conversions:</v-subheader>
      <v-layout row>
        <v-flex shrink style="width: 5em">
          <v-text-field
            v-model="minimumConversions"
            class="mt-0 number-input__hide-arrows"
            hide-details
            single-line
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex class="px-2">
          <v-slider
            v-model="minimumConversions"
            thumb-label="always"
            max="300"
          ></v-slider>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-form>
</template>
<script type="text/babel">
import VSelectLocale from "../../locale/VSelectLocale";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteWebsite from "@/components/website/VAutocompleteWebsite";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";

const { mapComputedFilters } = createNamespacedHelpers(
  "metrics-website-monthly"
);

export default {
  name: "metrics-website-monthly-filters",
  components: {
    BetweenDateInput,
    VAutocompleteSubject,
    VAutocompleteWebsite,
    VSelectLocale
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapComputedFilters([
      "locales",
      "websites",
      "subjects",
      "from",
      "to",
      "minimumVisitors",
      "minimumConversions"
    ]),
    betweenDates: {
      get() {
        return { from: this.from, to: this.to };
      },
      set(dates) {
        this.from = dates.from;
        this.to = dates.to;
      }
    }
  }
};
</script>
<style>
.number-input__hide-arrows input::-webkit-outer-spin-button,
.number-input__hide-arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
