<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :required="required"
    :search-input.sync="search"
    :no-filter="isAsync"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    :prepend-icon="subjectIcon"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip :selected="selected" :disabled="isDisabled" small label>
        <v-icon left>{{ getIconBySubjectType(item.type) }}</v-icon>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-tile-avatar>
        <v-icon>{{ getIconBySubjectType(item.type) }}</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title
          v-html="addSearchHighlightMarkup(item.name)"
        ></v-list-tile-title>
        <v-list-tile-sub-title v-text="item.type"></v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import { isNil } from "lodash";
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";

const TYPE_SECTOR = "SECTOR";
const TYPE_SUBSECTOR = "SUBSECTOR";
const TYPE_JOB = "JOB";
const TYPES = [TYPE_SECTOR, TYPE_SUBSECTOR, TYPE_JOB];
const GETTERS = {
  [TYPE_SECTOR]: "sectors",
  [TYPE_SUBSECTOR]: "subsectors",
  [TYPE_JOB]: "jobs"
};
const ICON_SECTOR = "fas fa-fw fa-circle";
const ICON_SUBSECTOR = "fas fa-fw fa-dot-circle";
const ICON_JOB = "far fa-fw fa-dot-circle";
const ICON_ALL = "far fa-fw fa-dot-circle";
const ICONS = {
  [TYPE_SECTOR]: ICON_SECTOR,
  [TYPE_SUBSECTOR]: ICON_SUBSECTOR,
  [TYPE_JOB]: ICON_JOB,
  all: ICON_ALL
};

export default {
  name: "v-autocomplete-subject",
  mixins: [VAutocompleteMixin],
  props: {
    sector: {
      type: Boolean,
      default: false
    },
    subsector: {
      type: Boolean,
      default: false
    },
    job: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    store: "subjects-select",
    defaultLabel: "subject"
  }),
  watch: {
    subjectType: {
      handler(newValue) {
        this.getter = TYPES.includes(newValue) ? GETTERS[newValue] : null;
      },
      immediate: true
    }
  },
  computed: {
    subjectType() {
      if (this.job) {
        return TYPE_JOB;
      }
      if (this.subsector) {
        return TYPE_SUBSECTOR;
      }
      if (this.sector) {
        return TYPE_SECTOR;
      }
      return "all";
    },
    subjectIcon() {
      if (!isNil(this.prependIcon)) {
        return this.prependIcon;
      }
      if (!this.isMultiple && !isNil(this.value)) {
        const item = (this.items || []).find(item => item.id === this.value);
        if (!isNil(item)) {
          return this.getIconBySubjectType(item.type);
        }
      }
      return this.getIconBySubjectType(this.subjectType);
    }
  },
  methods: {
    getIconBySubjectType(type) {
      return ICONS[type];
    }
  }
};
</script>
