<template>
  <tr v-if="index !== 0 || dataTypesArray.length > 1">
    <template v-if="index !== 0">
      <td>
        <a :href="'//' + website.host" target="_blank">{{ website.host }}</a>
      </td>
      <td v-for="(count, key) in sortedDataCounts" :key="key" class="data__td">
        <div class="data__container">
          <div v-if="hasVisitors">
            {{ count.unique_users }}<br />
            <div>
              LM
              <span :style="getColor(count.unique_users_monthly_change)">
                {{ round(count.unique_users_monthly_change) }}%
              </span>
            </div>
            <div>
              LY
              <span :style="getColor(count.unique_users_yearly_change)">
                {{ round(count.unique_users_yearly_change) }}%
              </span>
            </div>
          </div>
          <div v-if="hasConversions">
            {{ count.total_conversions }}<br />
            <div>
              LM
              <span :style="getColor(count.total_conversions_monthly_change)">
                {{ round(count.total_conversions_monthly_change) }}%
              </span>
            </div>
            <div>
              LY
              <span :style="getColor(count.total_conversions_yearly_change)">
                {{ round(count.total_conversions_yearly_change) }}%
              </span>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template v-else>
      <td />
      <td v-for="(count, key) in sortedDataCounts" :key="key">
        <div class="data__container">
          <div v-if="hasVisitors">Visitors</div>
          <div v-if="hasConversions">Conversions</div>
        </div>
      </td>
    </template>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers as createNamespacedHelpersVuex } from "vuex";
import { createNamespacedHelpers } from "@/store/helpers";
import { isNil } from "lodash";

import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpersVuex("auth");
const { mapGetters: mapWebsiteGetters } = createNamespacedHelpersVuex(
  "metrics-website-monthly"
);
const { mapComputedFilters } = createNamespacedHelpers(
  "metrics-website-monthly"
);

export default {
  name: "metrics-website-monthly-table-row",
  props: {
    websiteId: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapComputedFilters(["dataTypes"]),
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapWebsiteGetters([FIND_BY_ID, "dataCounts"]),
    website() {
      return this[FIND_BY_ID](this.websiteId);
    },
    sortedDataCounts() {
      return this.dataCounts(this.website.id);
    },
    dataTypesArray: {
      get() {
        return this.dataTypes.split(",");
      },
      set(val) {
        if (val) {
          this.dataTypes = val.join(",");
        }
      }
    },
    hasVisitors() {
      return this.dataTypesArray.includes("unique_users");
    },
    hasConversions() {
      return this.dataTypesArray.includes("total_conversions");
    }
  },
  methods: {
    round(number) {
      if (isNil(number)) {
        return "/";
      }
      return parseFloat(number).toFixed(2);
    },
    getColor(number) {
      switch (true) {
        case number > 0:
          return "color: green";
        case number === 0:
          return "color: black";
        case number < 0:
          return "color: red";
        default:
          return "color: gray";
      }
    }
  }
};
</script>
<style scoped lang="scss">
.data {
  &__td {
    padding: {
      top: 12px !important;
      bottom: 12px !important;
    }
  }
  &__container {
    display: flex;
    > div {
      width: 80px;
      + div {
        margin-left: 12px;
      }
    }
  }
  &__single-metric {
    display: flex;
    flex-direction: column;
  }
}
</style>
