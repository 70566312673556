import store from "@/store";
import { CLEAR, SET_UNREGISTER } from "@/store/templates/crud/mutation-types";
import { FETCH } from "@/store/templates/crud/action-types";

const fallbackModuleFetchIdFunc = (route, name) => {
  return route.params[`${name}Id`];
};

export const mapDetailViewLifecycleMethods = (name, module, fetchIdFunc) => {
  return {
    beforeRouteEnter: async (to, from, next) => {
      // If store does not exist yet, create it. If it does exist, make sure to set it's
      // unregister property to false, so it doesn't get unregistered by any components in
      // their destroy lifecycle method.
      if (!store.state[name]) {
        const internalModule = typeof module === "function" ? module() : module;
        console.debug(`[VUEX] Registering ${name} module.`);
        store.registerModule(name, internalModule);
      } else {
        store.commit(`${name}/${SET_UNREGISTER}`, false);
      }
      // Uses the provided function to determine which id to have the store module fetch.
      // If no function is provided, use the default one.
      const moduleFetchId = (fetchIdFunc || fallbackModuleFetchIdFunc)(
        to,
        name
      );
      await store.dispatch(`${name}/fetch`, { id: moduleFetchId });
      next();
    },
    beforeRouteUpdate: async (to, from, next) => {
      // If store does not exist yet, create it. If it does exist, make sure to set it's
      // unregister property to false, so it doesn't get unregistered by any components in
      // their destroy lifecycle method.
      if (!store.state[name]) {
        const internalModule = typeof module === "function" ? module() : module;
        console.debug(`[VUEX] Registering ${name} module.`);
        store.registerModule(name, internalModule);
      } else {
        store.commit(`${name}/${SET_UNREGISTER}`, false);
      }
      // Uses the provided function to determine which id to have the store module fetch.
      // If no function is provided, use the default one.
      const moduleFetchId = (fetchIdFunc || fallbackModuleFetchIdFunc)(
        to,
        name
      );
      await store.dispatch(`${name}/${FETCH}`, { id: moduleFetchId });
      next();
    },
    beforeRouteLeave: (to, from, next) => {
      // Mark this store for unregistering. The destroyed lifecycle event will now
      // unregister the store unless another components needs it before that time.
      store.commit(`${name}/${SET_UNREGISTER}`, true);
      next();
    },
    destroyed() {
      // Unregister this module if it exists and it's unregister property is true.
      if (store.state[name] && store.state[name].unregister) {
        console.debug(`[VUEX] Unregistering ${name} module.`);
        store.unregisterModule(name);
      }
    }
  };
};

export const mapCreateViewLifecycleMethods = (name, module) => {
  return {
    beforeRouteEnter: async (to, from, next) => {
      // If store does not exist yet, create it. If it does exist, make sure to set it's
      // unregister property to false, so it doesn't get unregistered by any components in
      // their destroy lifecycle method AND be sure to clear it of data.
      if (!store.state[name]) {
        const internalModule = typeof module === "function" ? module() : module;
        console.debug(`[VUEX] Registering ${name} module.`);
        store.registerModule(name, internalModule);
      } else {
        store.commit(`${name}/${CLEAR}`);
        store.commit(`${name}/${SET_UNREGISTER}`, false);
      }
      next();
    },
    beforeRouteLeave: (to, from, next) => {
      // Mark this store for unregistering. The destroyed lifecycle event will now
      // unregister the store unless another components needs it before that time.
      store.commit(`${name}/${SET_UNREGISTER}`, true);
      next();
    },
    destroyed() {
      // Unregister this module if it exists and it's unregister property is true.
      if (store.state[name] && store.state[name].unregister) {
        console.debug(`[VUEX] Unregistering ${name} module.`);
        store.unregisterModule(name);
      }
    }
  };
};

export const mapOverviewLifecycleMethods = (name, module) => {
  return {
    beforeRouteEnter: async (to, from, next) => {
      // If store does not exist yet, create it.
      if (!store.state[name]) {
        const internalModule = typeof module === "function" ? module() : module;
        store.registerModule(name, internalModule);
        console.debug(`[VUEX] Registering ${name} module.`);
      }
      next();
    }
  };
};
